import Http from '@/services/http'
import { buildQueryString } from '@/utils/http'
import {
  Product,
  Collection,
  SearchProductParams,
  SearchResults,
} from '@/types'
import { Search } from './search'
import {
  SEARCH_RESTRICT_BY_QUERY_STRING_PREFIX,
  convertSnakeCaseToTitleCase,
  PRODUCT_TAG_DISCOUNT_DEFAULT,
} from '@/utils'

export class SibSearch implements Search {
  async searchProduct(payload: SearchProductParams): Promise<SearchResults> {
    const params = {
      search: payload.query,
      store: import.meta.env.VITE_SEARCH_STORE,
    }
    if (payload.restrictByTags) {
      params['tags'] = payload.restrictByTags
    }

    const url = `${
      import.meta.env.VITE_SIB_SEARCH_DOMAIN
    }/api/search?${buildQueryString(params)}`
    const response = await Http.get(url)

    const result = {
      collections: response.data.collections?.map((collection) => {
        return {
          handle: `/collections/${collection.handle}`,
          title: collection.title,
        } as Collection
      }),
      products: response.data.products.map((product) => {
        const discountDefaultTag = product.tags?.find((tag) =>
          tag.includes(PRODUCT_TAG_DISCOUNT_DEFAULT)
        )
        const valueDiscount = discountDefaultTag?.split('_').at(-1)
        const variantProduct = {
          ...product.variant,
          price:
            discountDefaultTag && valueDiscount
              ? product.variant.price * ((100 - valueDiscount / 10) / 100)
              : product.variant.price,
        }
        return {
          handle: product.handle,
          images: product.images?.map((image) => {
            return {
              src: image.url,
              alt: image.altText || product.title,
              width: image?.width,
              height: image?.height,
            }
          }),
          title: product.title,
          productType: product.product_type,
          variants: [variantProduct],
          tags: product.tags,
          price: variantProduct.price,
        } as Product
      }),
      totalProducts: response.data.total_product,
    }
    return result
  }

  async searchProductFull(
    payload: SearchProductParams
  ): Promise<SearchResults> {
    const params: SearchProductParams = {
      search: payload.query,
      store: payload.store || import.meta.env.VITE_SEARCH_STORE,
      page: payload.page || 1,
      sort_by: payload.sortBy || '',
      order_by: payload.sortOrder || '',
    }

    for (const key in payload) {
      if (!key.startsWith(SEARCH_RESTRICT_BY_QUERY_STRING_PREFIX)) continue
      if (Object.prototype.hasOwnProperty.call(payload, key)) {
        const values = payload[key]
        const keyWithoutPrefix = key
          .split(SEARCH_RESTRICT_BY_QUERY_STRING_PREFIX)
          .pop()

        const paramKey = `${keyWithoutPrefix}`

        if (Array.isArray(values) && values.length) {
          const searchANDNotValue = values.filter((value) =>
            value.startsWith('!')
          )
          const searchORValue = values.filter((value) => !value.startsWith('!'))
          if (searchANDNotValue.length) {
            params[paramKey] = `${searchANDNotValue.join(',')}${
              searchORValue.length ? ',' : ''
            }${searchORValue.join('|')}`
          } else {
            params[paramKey] = values.join('|')
          }
        } else {
          params[paramKey] = values
        }
      }
    }

    const url = `${
      import.meta.env.VITE_SIB_SEARCH_DOMAIN
    }/api/products?${buildQueryString(params)}`

    const response = await Http.get(url)
    const result: SearchResults = {
      products: response.data.products.map((product) => {
        const discountDefaultTag = product.tags?.find((tag) =>
          tag.includes(PRODUCT_TAG_DISCOUNT_DEFAULT)
        )
        const valueDiscount = discountDefaultTag?.split('_').at(-1)
        const variantProduct = {
          ...product.variant,
          price:
            discountDefaultTag && valueDiscount
              ? product.variant.price * ((100 - valueDiscount / 10) / 100)
              : product.variant.price,
        }

        return {
          id: product.id,
          handle: product.handle,
          images: product.images?.map((image) => {
            return {
              src: image.url,
              alt: image.altText || product.title,
              width: image?.width,
              height: image?.height,
            }
          }),
          title: product.title,
          productType: product.product_type,
          variants: [variantProduct],
          tags: product.tags,
          price: variantProduct.price,
          mkc_detail: product.mkc_detail,
        } as Product
      }),
      totalProducts: response.data.totalProducts,
      totalPages: response.data.totalPages,
      facets: Object.keys(response.data.others).map((key) => {
        if (Array.isArray(response.data.others[key])) {
          return {
            title: convertSnakeCaseToTitleCase(key),
            attribute: key,
            values: response.data.others[key].map((item) => ({
              title: item.title,
              attribute: item.handle,
              buckets: Object.keys(item.collections).map((key) => ({
                value: key,
                count: item.collections[key],
              })),
            })),
          }
        } else {
          return {
            title: convertSnakeCaseToTitleCase(key),
            attribute: key,
            buckets: Object.keys(response.data.others[key]).map((keyChild) => ({
              value: keyChild,
              count: response.data.others[key][keyChild],
            })),
          }
        }
      }),
    }

    return result
  }

  async searchProductByCollection(
    payload: SearchProductParams
  ): Promise<SearchResults> {
    const params: SearchProductParams = {
      store: import.meta.env.VITE_SEARCH_STORE,
      page: payload.page || 1,
      sort_by: payload.sortBy || '',
      order_by: payload.sortOrder || '',
    }
    for (const key in payload) {
      if (!key.startsWith(SEARCH_RESTRICT_BY_QUERY_STRING_PREFIX)) continue
      if (Object.prototype.hasOwnProperty.call(payload, key)) {
        const values = payload[key]
        const keyWithoutPrefix = key
          .split(SEARCH_RESTRICT_BY_QUERY_STRING_PREFIX)
          .pop()

        const paramKey = `${keyWithoutPrefix}`

        if (Array.isArray(values) && values.length) {
          const searchANDNotValue = values.filter((value) =>
            value.startsWith('!')
          )
          const searchORValue = values.filter((value) => !value.startsWith('!'))
          if (searchANDNotValue.length) {
            params[paramKey] = `${searchANDNotValue.join(',')}${
              searchORValue.length ? ',' : ''
            }${searchORValue.join('|')}`
          } else {
            params[paramKey] = values.join('|')
          }
        } else {
          params[paramKey] = values
        }
      }
    }

    if (payload.collection == 'digital-products') {
      params['store'] = 'digital'
    }
    const url = `${import.meta.env.VITE_SIB_SEARCH_DOMAIN}/api/collections/${
      payload.collection
    }?${buildQueryString(params)}`

    const response = await Http.get(url)
    const result: SearchResults = {
      products: response.data.products.map((product) => {
        const discountDefaultTag = product.tags?.find((tag) =>
          tag.includes(PRODUCT_TAG_DISCOUNT_DEFAULT)
        )
        const valueDiscount = discountDefaultTag?.split('_').at(-1)
        const variantProduct = {
          ...product.variant,
          price:
            discountDefaultTag && valueDiscount
              ? product.variant.price * ((100 - valueDiscount / 10) / 100)
              : product.variant.price,
        }
        return {
          id: product.id,
          handle: product.handle,
          images: product.images?.map((image) => {
            return {
              src: image.url,
              alt: image.altText || product.title,
              width: image?.width,
              height: image?.height,
            }
          }),
          title: product.title,
          productType: product.product_type,
          variants: [variantProduct],
          tags: product.tags,
          price: variantProduct.price,
          mkc_detail: product.mkc_detail,
        } as Product
      }),
      totalProducts: response.data.totalProducts,
      totalPages: response.data.totalPages,
      facets: Object.keys(response.data.others).map((key) => {
        if (Array.isArray(response.data.others[key])) {
          return {
            title: convertSnakeCaseToTitleCase(key),
            attribute: key,
            values: response.data.others[key].map((item) => ({
              title: item.title,
              attribute: item.handle,
              buckets: Object.keys(item.collections).map((key) => ({
                value: key,
                count: item.collections[key],
              })),
            })),
          }
        } else {
          return {
            title: convertSnakeCaseToTitleCase(key),
            attribute: key,
            buckets: Object.keys(response.data.others[key]).map((keyChild) => ({
              value: keyChild,
              count: response.data.others[key][keyChild],
            })),
          }
        }
      }),
    }

    return result
  }
}

export default SibSearch
